<template>
  <div class="bank">
    <div class="navTitle"><img src="@/assets/img/arrl.png" alt="" @click="goBack">我的桂信码</div>
    <div class="card">
      <p class="text">“桂信码”作为广西企业统一“数字信用身份证”，是平台为每家注册并完成实名认证企业赋予的专属信用二维码。企业出示“桂信码”，交易对手识别该二维码，即可快速浏览企业公开的基本信息；在企业授权的情况下，交易对手还可查看授权企业全方位的信用情况。</p>
      <div class="tit">—  亮码业务场景  —</div>
      <div class="flex">
        <div class="scene border-right">
          <img src="@/assets/img/gxm_icon1.png" alt="">
          <p class="title">免证办事</p>
          <p>免带纸质凭证，亮码可办事</p>
        </div>
        <div class="scene">
          <img src="@/assets/img/gxm_icon2.png" alt="">
          <p class="title">信息授权</p>
          <p>查看授权企业全方位信用情况</p>
        </div>
      </div>
      <div style="text-align: center">
        <div class="yellowc" @click="checkLogin()">立即亮码</div>
      </div>
    </div>
  </div>
</template>
<script>
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { Toast,Dialog } from 'vant';
export default {
   setup() {
     let store = useStore();
     let router = useRouter();
     // 检查登录
      const checkLogin = () => {

          if(store.state.token) {
            router.push({name:'gxm',params:{page:'back'}})
          }else {
            Dialog.confirm({
              title: '提示！',
              confirmButtonText: '去登录',
              message:
                '您还没有登录！点击下方按钮去登录。',
              })
              .then(() => {
                router.push({path: '/login'});
              })
              .catch(() => {
                console.log('取消登录!')
              });
          }
      };
    return {
      checkLogin
     };
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },
  }
}
</script>
<style lang="less" scoped>
.bank {
  padding-top: 12.5vw;
  .card {
    img {
      width: 100%;
      height: auto;
      border-radius: 1.5vw;
    }
    p.text {
      margin-top: 3vw;
      font-size: 4vw;
      letter-spacing: 1px;
      line-height: 6vw;
    }
    .tit {
      text-align: center;
      color: #669aff;
      font-size: 4.8vw;
    }
    .scene {
      width: 50%;
      text-align: center;
      padding: 0 4vw;
      margin: 8vw 0;
      img {
        height: 16.9vw;
        width: auto;
      }
      p {
        font-size: 3.5vw;
        color: #999999;
        line-height: 6vw;
      }
      p.title {
        font-size: 4vw;
        color: #333;
        font-weight: 600;
      }
    }
    .border-right {
      border-right: 1px solid #ccc;
    }
    .yellowc {
      display: inline-block;
            font-size: 4.5vw;
            width: 26vw;
            padding: 1vw 2.5vw;
            border-radius: 6vw;
            margin: 4vw 2vw; 
            border: 0;
            background: linear-gradient(to right, #ff9900, #ff6700);
            color: #fff;
            text-align: center;
        }
  }
}
</style>
